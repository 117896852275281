const themes = [
  { name: "INTERIOR", color: "#F8B11A" },
  { name: "VISUALS", color: "#334395" },
  { name: "FURNITURE", color: "#CD3025" },
  { name: "PHOTOGRAPHY", color: "#175543" },
];

let currentIndex = 0;

setInterval(() => {
  currentIndex++;
  if (currentIndex >= themes.length) currentIndex = 0;
  const { name, color } = themes[currentIndex];

  document.querySelector(".js-word").textContent = name;
  document.querySelector("html").style.setProperty("--bg", color);
}, 3500);
